import { useUnit } from 'effector-react';
import { useEffect } from 'react';
import { isServer } from '@/shared/app/isServer';
import { createEvent, createStore, sample } from 'effector';
import { EScreenSize, screenSizes, TScreen } from './screensize';
export const xsmallScreenMediaQuery = `(max-width: ${screenSizes.xs})`;
export const smallScreenMediaQuery = `(min-width: ${screenSizes.xs}) and (max-width: ${screenSizes.md})`;
export const mediumScreenMediaQuery = `(min-width: ${screenSizes.sm}) and (max-width: ${screenSizes.md})`;
export const largeScreenMediaQuery = `(min-width: ${screenSizes.md})`;
export const $screenSize = createStore<keyof TScreen | null>(null, {
	name: 'screenSize',
});
export const screenSizeUpdated = createEvent<keyof TScreen | null>();

sample({
	clock: screenSizeUpdated,
	target: $screenSize,
});
export const useScreenSize = (): keyof TScreen | null => {
	const [screenSize, setScreenSize] = useUnit([$screenSize, screenSizeUpdated]);
	useEffect(() => {
		if (isServer) {
			return;
		}

		const xsmallMql = window.matchMedia(xsmallScreenMediaQuery);
		const smallMql = window.matchMedia(smallScreenMediaQuery);
		const mediumMql = window.matchMedia(mediumScreenMediaQuery);
		const largeMql = window.matchMedia(largeScreenMediaQuery);

		//init
		if (xsmallMql.matches) {
			setScreenSize(EScreenSize.xs);
		} else if (smallMql.matches) {
			setScreenSize(EScreenSize.sm);
		} else if (mediumMql.matches) {
			setScreenSize(EScreenSize.md);
		} else if (largeMql.matches) {
			setScreenSize(EScreenSize.lg);
		}
		const xsmallMqlListener = (e: MediaQueryListEvent) => {
			if (e.matches) {
				setScreenSize(EScreenSize.xs);
			}
		};
		const smallMqlListener = (e: MediaQueryListEvent) => {
			if (e.matches) {
				setScreenSize(EScreenSize.sm);
			}
		};
		const mediumMqlListener = (e: MediaQueryListEvent) => {
			if (e.matches) {
				setScreenSize(EScreenSize.md);
			}
		};
		const largeMqlListener = (e: MediaQueryListEvent) => {
			if (e.matches) {
				setScreenSize(EScreenSize.lg);
			}
		};

		//resize
		xsmallMql.addEventListener('change', xsmallMqlListener);
		smallMql.addEventListener('change', smallMqlListener);
		mediumMql.addEventListener('change', mediumMqlListener);
		largeMql.addEventListener('change', largeMqlListener);
		return () => {
			xsmallMql.removeEventListener('change', xsmallMqlListener);
			smallMql.removeEventListener('change', smallMqlListener);
			mediumMql.removeEventListener('change', mediumMqlListener);
			largeMql.removeEventListener('change', largeMqlListener);
		};
	}, [setScreenSize]);

	return screenSize;
};
